// Import Functions from Vuex, firebase-auth, firebase-firestore and firebase-storage
import { createStore } from "vuex";
import { auth, db, storage} from "@/firebase/config";
import {
        createUserWithEmailAndPassword, 
         signInWithEmailAndPassword,
         signOut,onAuthStateChanged, 
         updateProfile,
         updatePassword,
         sendPasswordResetEmail
         } from "firebase/auth";
import { collection,addDoc, getDocs, orderBy,getDoc,doc, deleteDoc, deleteField,updateDoc  } from "firebase/firestore";
import { ref, uploadBytes,getDownloadURL } from "firebase/storage";
//  Intialize the store
const store = createStore({
    // Set an intial value for the state's data
    state: {
        user: null,
        IsVerified:false,
        authIsReady:false,
        images:[],
        proof:'',
        adminId:'0MgQ0AWHZOQqZ1SobxwG7z2ZNzs2',
        Transactions:[],
        Transaction:[],
        total:null
    },
    mutations:{
        setUser(state,payload) {
            state.user = payload
            console.log('user state changed,', state.user)
        },
        setAuthIsReady(state,payload)  {
            state.authIsReady = payload
        }
    },
    actions: {
       async signup(context,{email, password,name}) {

            const res = await createUserWithEmailAndPassword(auth,email,password)
            if(res) {
                context.commit('setUser',res.user)
                updateProfile(res.user,{
                    displayName: name
                  })
                  console.log('Succesful')
            }
            else {
                throw new Error('Something went wrong')
            }
        },
        async login(context,{email,password}) {
            const resp = await signInWithEmailAndPassword(auth,email,password)
            if (resp) {
                context.commit('setUser', resp.user)
            }
            else {
                throw new Error('Something went wrong')
            }
        },
        async username_update(context,{name}) {
          updateProfile(this.state.user,{
            displayName: name
          })
        },
        async change(_,pass) {
          updatePassword(this.state.user,pass)
        },
        async sendLink(_,email) {
          sendPasswordResetEmail(auth, email)
        },
        async logout(context) {
            await signOut(auth)
            context.commit('setUser', null)
        },
    async upLoadFront(_,{Image}) {
        try {
            const Ref = ref(storage,"products" );
                    const ImagesRef = ref(Ref, Image.name)
                    Ref.name === ImagesRef.name; 
                    Ref.fullPath === ImagesRef.fullPath;  
                    const metadata = {
                        contentType: 'image/jpeg',
                      };
                    await uploadBytes(ImagesRef, Image, metadata).then((snapshot) => {
                        console.log(Image,snapshot);
                      });
                      
                    await setTimeout(() => {
                        getDownloadURL(ref(Ref,Image.name)).then((url) => {
                            this.state.front = url
                            console.log(this.state.imageUrl)
                        })
                    }, 1000)
        }
        catch (error) {
            console.error('Error adding image to Firestore Storage:', error);
          }
    },
    async upLoad(_, { Images }) {
      try {
        const uploadPromises = Images.map(async (Image) => {
          const Ref = ref(storage, "products");
          const ImagesRef = ref(Ref, Image.name);
          const metadata = {
            contentType: 'image/jpeg',
          };
    
          // Upload each image
          const snapshot = await uploadBytes(ImagesRef, Image, metadata);
          console.log('Uploaded Image:', snapshot);
    
          // Get the download URL
          const downloadURL = await getDownloadURL(ImagesRef);
          console.log('Download URL:', downloadURL);
    
          return downloadURL; // Return the download URL for this file
        });
    
        // Wait for all uploads to finish
        const urls = await Promise.all(uploadPromises);
        
        // Save all URLs in the state
        this.state.images = urls;
        console.log('All uploaded URLs:', this.state.back);
      } catch (error) {
        console.error('Error uploading images to Firebase Storage:', error);
      }
    },
    
  async upLoadProof(_,{Image}) {
    try {
        const Ref = ref(storage,"products" );
                const ImagesRef = ref(Ref, Image.name)
                Ref.name === ImagesRef.name; 
                Ref.fullPath === ImagesRef.fullPath;  
                const metadata = {
                    contentType: 'image/jpeg',
                  };
                await uploadBytes(ImagesRef, Image, metadata).then((snapshot) => {
                    console.log(Image,snapshot);
                  });
                  
                await setTimeout(() => {
                    getDownloadURL(ref(Ref,Image.name)).then((url) => {
                        this.state.proof = url
                        console.log(this.state.imageUrl)
                    })
                }, 1000)
    }
    catch (error) {
        console.error('Error adding image to Firestore Storage:', error);
      }
},
        async AddGiftCard(_,{Type,GiftCard_Type,Category,SubCategory,Country,Amount,Rate,Naira_Amount,Bank,AccountName,AccountNumber,Images,UserID,Date,status}) {
                  try {
                    await setTimeout(() => {
                        addDoc(collection(db, "Transactions"),{Type,GiftCard_Type,Category,SubCategory,Country,Amount,Rate,Naira_Amount,Bank,AccountName,AccountNumber,Images,Email:this.state.user.email,UserID,Date,status});
                    }, 3000)
                  } catch (error) {
                    console.error('Error adding item to Firestore:', error);
                  }
        },
        async AddCrypto(_,{Type,Name,Naira_Amount,Dollar_Amount,Bank,AccountName,AccountNumber,Image,UserID,Date,status}) {
          try {
            await setTimeout(() => {
                addDoc(collection(db, "Transactions"),{Type,Name,Naira_Amount,Dollar_Amount,Bank,AccountName,AccountNumber,Image,Email:this.state.user.email,UserID,Date,status});
            }, 3000)
          } catch (error) {
            console.error('Error adding item to Firestore:', error);
          }
},
async UpdateStatus(_,{docId}) {
  try {
    const docRef = doc(db, "Transactions", docId);  // Get reference to the document by docId
    await updateDoc(docRef, { status: "Confirmed" });  // Update the status field
    console.log(' status successfully updated!');
  } catch (error) {
    console.error('Error updating gift card status:', error);
  }
},
async getTransactions() {
  try {
    const latest = await getDocs(
        collection(db, 'Transactions'),
        orderBy('date', '')

    );

    latest.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      this.state.Transactions.push(data);
    });

    setTimeout(() => {
      this.state.Transactions = [];
    }, 500);

    console.log(this.state.Transactions);
  } catch (error) {
    console.error('Error fetching transactions: ', error);
  }
},

        async getById(_,{documentId}) {
            const documentRef = doc(db,'Transactions',  documentId);
            const documentSnapshot = await getDoc(documentRef);
          
            if (documentSnapshot.exists()) {
            
              this.state.Transaction.push(documentSnapshot.data())
              console.log(this.state.Transaction)
              setTimeout(() => {
                this.state.Transaction =[]
            }, 500);
            } 
            else {
            return null;
            }
          },
          async AddTocart(_,{Name,Price,Quantity,Url}) {
            try {
              await setTimeout(() => {
                  addDoc(collection(db, `UsersDetails/Cart/${this.state.user.uid}`),{Name,Price,Quantity,Url});
              }, 3000)
            } catch (error) {
              console.error('Error adding item to cart:', error);
            }
  },
  async  deleteFromCart(_,{Id}) {
    const documentRef = doc(db, `UsersDetails/Cart/${this.state.user.uid}`, Id);
    try {
        await setTimeout(() => {
             deleteDoc(documentRef);
             console.log('Document successfully deleted!');
            // deleteDoc(collection(db, `UsersDetails/Cart/${this.state.user.uid}`),Id);
        }, 3000)
      } catch (error) {
        console.error('Error deleting Item', error);
      }
  },
  deleteProducts(_,{Id}) {
    const Ref = doc(db,'Products',Id)
    try {
        deleteDoc(Ref)
        console.log('Succes')
    }
    catch(error) {
        console.log('Error happened during deleting process', error)
    }
  },
  async clearCart() {
    const documentRef = doc(collection(db, `UsersDetails/Cart/${this.state.user.uid}`));
    await updateDoc(documentRef, {
        Name: deleteField(),
        Quantity: deleteField(),
        Price:deleteField(),
        Url:deleteField()
    });
  },
  async getCart(_,{ Id }) {
const latest =await getDocs(collection(db,`UsersDetails/Cart/${Id}`));
    latest.forEach((doc) => {
     const data = doc.data()
     data.id = doc.id
     this.state.Cart.push(data);
     console.log(this.state.Cart)
     setTimeout(() => {
         this.state.Cart =[]
     }, 500);
   });
    }
}
})
const unsub=onAuthStateChanged(auth, (user)=> {
    store.commit('setAuthIsReady',true)
    store.commit('setUser',user)
    unsub()
})
export default store