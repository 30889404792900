<template>
    <LoaDer v-if="loading" />
    <template v-else>
        <div class="trans-details">
            <header>Transaction Details</header>
            <div v-for="(Pro, i) in Trans" :key="i">
                <div class="table">
                    <table>
                        <tbody>
                            <tr>
                                <td>Transaction ID</td>
                                <td>{{ $route.params.id }}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td> {{ Pro.Type }} </td>
                            </tr>
                            <tr v-if="Pro.Name">
                                <td>Crypto Name</td>
                                <td>{{ Pro.Name ? Pro.Name : "-------" }}</td>
                            </tr>
                            <tr v-if="Pro.Category">
                                <td>GiftCard Category (Name)</td>
                                <td>{{ Pro.Category ? Pro.Category : "-------" }}</td>
                            </tr>
                            <tr v-if="Pro.SubCategory">
                                <td>GiftCard Subcategory (Range)</td>
                                <td>{{ Pro.SubCategory ? Pro.SubCategory : "-------" }}</td>
                            </tr>
                            <tr>
                                <td>Amount(Naira)</td>
                                <td>{{ Pro.Naira_Amount ? `₦${Pro.Naira_Amount}` : "-------" }}</td>
                            </tr>
                            <tr v-if="Pro.Dollar_Amount">
                                <td>Amount(Dollar)</td>
                                <td>{{ Pro.Dollar_Amount ? `$${Pro.Dollar_Amount}` : "-------" }}</td>
                            </tr>
                            <tr>
                                <td>Bank</td>
                                <td>{{ Pro.Bank }}</td>
                            </tr>
                            <tr>
                                <td>Account Name</td>
                                <td>{{ Pro.AccountName }}</td>
                            </tr>
                            <tr>
                                <td>Account Number</td>
                                <td>{{ Pro.AccountNumber }}</td>
                            </tr>
                            <tr>
                                <td>Date and Time</td>
                                <td> {{ formatDate(Pro.Date) }} </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="admin">
                         <div v-for="(Img,i) in Pro.Images" :key="i">
                           <img :src="Img" alt="">
                         </div>
                        <img :src="Pro.FrontImage" alt="" v-if="Pro.FrontImage">
                        <img :src="Pro.BackImage" alt="" v-if="Pro.BackImage">
                        <img :src="Pro.Image" alt="" v-if="Pro.Image">
                        <div class="butt">
                            <button @click="Confirm(); sendEmail(Pro.Email,$route.params.id)" v-if="user.uid===Admin">Confirm Transaction</button>
                        </div>
                    </div>
                    <div class="nothing"></div>
                </div>
            </div>
        </div>
        <NavBar />
    </template>
</template>
<style>

header {
    background-color: #8a0346;
    color: white;
    padding: 12px;
}

.table {
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}


table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

td {
    padding: 12px;
    border: 1px solid #ccc;
}

tbody td {
    background-color: #f9f9f9;
}

tbody tr:nth-child(even) td {
    background-color: #e9e9e9;
}

.admin {
    text-align: center;
    margin: 20px auto;
}
.admin img {
    width: 50%;
    display: block;
    border: 1px solid #dc143c;
    border-radius: 20px;
    margin: 20px auto;
}
</style>

<script>
import emailjs from "@emailjs/browser";
import NavBar from '@/componets/NavBar.vue';
import LoaDer from '@/componets/LoaDer.vue';
export default {
    components: {
        NavBar,
        LoaDer
    },
    data() {
        return {
            loading: true,
            Trans: this.$store.state.Transaction,
        }
    },
    computed : {
        user() {
            return this.$store.state.user;
        },
        Admin() {
            return this.$store.state.adminId;
        }
    },
    mounted() {
        const { id } = this.$route.params;
        this.$store.dispatch('getById',
            { documentId: id })
        if (!this.Trans) {
            this.loading = true
        }
        if (this.Trans) {
            console.log(this.Trans)
            setTimeout(() => {
                this.loading = false
            }, 1000);
        }

    },
    methods: {
        Confirm(){
            this.$store.dispatch('UpdateStatus', {docId: this.$route.params.id})
        },
        formatDate(milliseconds) {
            const date = new Date(milliseconds);

            // Get the date part: Oct 01 2024
            const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
            const formattedDate = date.toLocaleDateString('en-US', dateOptions);

            // Get the time part: 07:23pm
            const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
            const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toLowerCase();

            // Combine the date and time
            return `${formattedDate} ${formattedTime}`;
        },
        sendEmail(email,id) {
            emailjs.send("service_30j920q", "template_61l07py", {
          message: `The Transaction with transaction id of  ${id} has been confirmed`,
          to: email,
      }, "Vu8FgLbZEt6ecS1Nj")
        .then((response) => {
          alert("Transaction Confirmed! Redirecting....", response.status, response.text);
        })
        .catch((error) => {
          alert("Failed to send email:", error);
        });
    },
    }
}
</script>